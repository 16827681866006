import React, { Component } from 'react';
import aboutImg from '../Assets/Images/jeremy-dickey.jpg';

class About extends Component {
    render() {
        return (
            <div className="hx-about-style-1" id="about">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-6 col-lg-6 d-xl-flex d-lg-flex d-block align-items-center">
                            <div className="hx-about-content">
                                <div className="hx-site-title">
                                    <h2>How can I help?</h2>
                                </div>
                                  <p> I am confident that I can be an immediate asset to any team, leveraging my extensive experience in Probelm solving, Research, and collaboration. With a strong background in both technical implementation and support, I excel at tackling complex challenges, optimizing systems, and ensuring smooth operations across various platforms.
                                  <br></br><br></br>
My passion lies in not only developing and implementing solutions but also in maintaining and enhancing them. I thrive in environments where I can contribute to every phase of a project, from designing and developing interfaces, dashboards, and websites to managing integrations, DevOps, and cloud services. My ability to quickly adapt and hit the ground running allows me to deliver results efficiently and effectively.
<br></br><br></br>
In addition to my development skills, I handle a wide range of tasks, including SSL management, OAuth, API integrations, and more. I am committed to continuous learning and always seek to expand my skill set to stay ahead in the fast-evolving tech landscape.
<br></br><br></br>
Some of the key technologies I am proficient in include:
<br></br><br></br>
<ul>
    
    <li> 
    Technical Support & Help Desk: Subject Matter Expert (SME), Customer Service, Troubleshooting
    </li>

    <li>
    Cloud & IT Administration: AWS, GCP, Splunk Enterprise, Splunk Cloud, Active Directory 
    </li>

    <li>
    Software Implementation & Scripting: SQL, Python, PHP, JSON, XSL
    </li>

    <li>
    Web Technologies & Tools: HTML, CSS, React, Angular, WordPress, Shopify
    </li>
    <li>
    Development Tools & Version Control: Git, Postman, VSCode, VIM
    </li>

    <li>
    API Integration & Support: API Management, Zapier, ClickFunnels
    </li>

    <li>
    Database Management: PostgreSQL, MySQL, DBeaver
    </li>

    <li>
    Design & SEO: Web Design, Figma, Affinity Photo, SEO
    </li>
    </ul>
    Technical Support & Help Desk: Subject Matter Expert (SME), Customer Service, Troubleshooting
    Cloud & IT Administration: AWS, GCP, Splunk Enterprise, Splunk Cloud, Active Directory
    Software Implementation & Scripting: SQL, Python, PHP, JSON, XSL
    Web Technologies & Tools: HTML, CSS, React, Angular, WordPress, Shopify
    Development Tools & Version Control: Git, Postman, VSCode, VIM
    API Integration & Support: API Management, Zapier, ClickFunnels
    Database Management: PostgreSQL, MySQL, DBeaver
    Design & SEO: Web Design, Figma, Affinity Photo, SEO
                                    </p>
                                
                                <div className="btns">
                                <a href="#protfolio" alt="See my projects" title="See my projects" className="theme-btn go-contact-area">Portfolio</a> </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5">
                            <div className="hx-about-img" alt="Jeremy Dickey" title="Jeremy Dickey" >
                                <img src={aboutImg} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;