import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faAccessibleIcon, faFacebookF, faTwitter, faInstagram, faPinterest, faPinterestP, faLinkedin, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../Assets/Images/logo.png';

class Footer extends Component {
    render() {
        return (
            <div className="footer-area text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-image">
                                <a href="index.html">
                                </a>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="footer-menu">
                            <div className="slide-title">
                                        <h2 style={{color: 'white'}}> Let's <span style={{color: 'Red'}}>Work Together!</span> <br></br><br></br></h2>
                                    </div>

                                    <div className="btns">
                                        <a href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3fsXlmUTW0J1ngFy7SAP1IaZYYzuRHHo3C9OCeDyHxG9C8jarP02p2RM6NJUM1evB1LVfeP1Mx" alt="calendar button" title="Calendar button" className="theme-btn go-contact-area">Calender</a>
                                        <a href="mailto:jeremy@jeremypdickey.com" alt="email me" title="email" className="theme-btn go-contact-area">Email</a>
                                        <br></br><br></br>
                                    </div>
                                <ul className="d-flex " >
                              
                              

                                <li>  
                               <a href="https://github.com/JDEE30/" alt="My GitHub" title="my GitHub">
                                <FontAwesomeIcon icon={faGithub} /> </a>
                        </li>

                     

                        <li>  
                               <a href="https://twitter.com/jeremypdickey" alt="My Twitter" title="My Twitter">
                                <FontAwesomeIcon icon={faTwitter} /> </a>
                        </li>

                        <li>  
                               <a href="https://www.facebook.com/Jeremypdickey" alt="My Facebook" title="My FaceBook">
                                <FontAwesomeIcon icon={faFacebook} /> </a>
                        </li>

                        <li>  
                               <a href="https://medium.com/@jeremydickey" alt="My Medium" title="My Medium">
                                <FontAwesomeIcon icon={faMedium} /> </a>
                        </li>


                                </ul>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="footer-sub">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;